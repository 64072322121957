.topCard {
  border-radius: 3px;
  margin-block-start: 10px;
  box-shadow: 1px 1px 0.5px #f0f0f0;
}

.cardTitle {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
}

.indicatorCard {
  border-radius: 3px;
  margin-block-start: 10px;

  /* box-shadow: 0px 0px 4px 1px rgba(170, 227, 185, 0.41); */
}

.bottomCard {
  background: rgba(255, 244, 128, 0.21);
}
