@media all and (max-width: 768px),
  only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 1024px),
  only screen and (min-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (min-resolution: 192dpi) and (max-width: 1024px),
  only screen and (min-resolution: 2dppx) and (max-width: 1024px) {
  .carr {
    top: 50% !important;
  }
}
