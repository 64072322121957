@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

.arrow_box {
    font-family: "Open Sans", sans-serif;
    position: relative;
    color: #000;
    font-weight: normal;
    font-size: 12px;
    background: #fff;
}

#chart .apexcharts-tooltip {
    color: #fff;
    transform: translateX(10px) translateY(10px);
    overflow: visible !important;
    white-space: normal !important;
}

#chart .apexcharts-tooltip span {
    padding: 2px 10px;
    display: inline-block;
}

#chart .apexcharts-tooltip p {
    font-weight: bold;
    margin: 0px;
    display: inline-block;
}
