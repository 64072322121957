.avatar {
  vertical-align: middle;
  width: 25px;
  height: 35px;
  border-radius: 50%;
}

@media all and (max-width: 375px),
  only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 1024px),
  only screen and (min-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (min-resolution: 192dpi) and (max-width: 1024px),
  only screen and (min-resolution: 2dppx) and (max-width: 1024px) {
  h2.subt {
    font-size: 10px !important;
    line-height: 5px;
  }
  span.subt {
    font-size: 10px !important;
    line-height: 5px;
  }
  .xs {
    max-width: 100px;
    visibility: hidden;
  }
  .xs span::after {
    visibility: visible;
    content: "Menu";
  }
}

.responsive {
  max-width: 100%;
  width: 35px;
  /* height: auto; */
}
.ant-popover-inner {
  color: black;
  background-color: lightgray;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: green;
}
