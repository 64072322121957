.drag-and-drop-wrapper {
    cursor: grab;
    min-height: 20px;
    width: 130px;
    height: auto;
    margin-right: 20px;
    padding: 0;
    list-style-type: none;
    overflow: auto;
}

.drag-enter {
    border: 2px dashed gray;
}

.row {
    box-sizing: border-box;
    background: #ccc;
    padding: 5px;
    margin: 0;
    font-size: 14px;
}

.row:hover {
    background: #edf4fa;
    cursor: grab;
}

.row:active {
    cursor: grabbing;
}

.drag {
    display: inline-block;
    color: grey;
    padding: 0px 3px;
    cursor: grab;
}

.drag:active {
    cursor: grabbing;
}
