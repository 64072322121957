@import url("https://fonts.googleapis.com/css?family=Inter&display=swap");

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Inter", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

body {
    background-color: #f0f2f5 !important;
}
