.card {
    border-radius: 3px;
    box-shadow: 1px 1px 0.5px #f0f0f0;
}

.card-bg {
    border-radius: 3px;
    box-shadow: 1px 1px 0.5px #f0f0f0;
    background: rgba(245, 245, 245, 1);
    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.98) 11%,
        rgba(247, 247, 247, 0.92) 53%,
        rgba(255, 255, 255, 0.89) 66%,
        rgba(255, 255, 255, 0.85) 96%,
        rgba(255, 255, 255, 0.84) 100%
    );
}
@media all and (max-width: 768px),
    only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px),
    only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 1024px),
    only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 1024px),
    only screen and (min-device-pixel-ratio: 2) and (max-width: 1024px),
    only screen and (min-resolution: 192dpi) and (max-width: 1024px),
    only screen and (min-resolution: 2dppx) and (max-width: 1024px) {
    .container {
        max-width: 100%;
        width: auto;
        padding: 16px 16px;
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
    }
    /* .container-date {
    max-width: 100%;
    width: auto;
    white-space: nowrap;
    display: flex;
  } */
}
.Tooltips {
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.p {
    margin-bottom: 0px !important;
}

.GreyTooltip {
    background-color: lightgrey !important;
    color: black !important;
    fill: #fff !important;
    font-size: 12px !important;
}

.Content_Content__1CE8z {
    font-size: 12px !important;
}
.chart-container {
    position: relative;
    margin: auto;
    height: 80vh;
    width: 100%;
}
@media only screen and (orientation: landscape) {
    #turn {
        display: block;
    }
    #container-tom {
        display: none;
    }
}
@media only screen and (orientation: portrait) {
    #turn {
        display: none;
    }
    #container-tom {
        display: block;
    }
}

.steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}

.steps-action {
    margin-top: 24px;
}

.ant-select-combobox .ant-select-arrow {
    display: block;
}

.ant-select-selection__clear {
    opacity: 1;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: left !important;
}

.ant-tabs {
    margin-top: 16px;
}

.ant-table-row:hover {
    cursor: pointer;
}

.ant-table-row:active {
    opacity: 0;
    transition: ease 0.5s;
}

.ant-popover-inner {
    background: #ffffff;
}
