@import url(https://fonts.googleapis.com/css?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Inter", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

body {
    background-color: #f0f2f5 !important;
}

.avatar {
  vertical-align: middle;
  width: 25px;
  height: 35px;
  border-radius: 50%;
}

@media all and (max-width: 375px),
  only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (min-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (min-resolution: 192dpi) and (max-width: 1024px),
  only screen and (min-resolution: 2dppx) and (max-width: 1024px) {
  h2.subt {
    font-size: 10px !important;
    line-height: 5px;
  }
  span.subt {
    font-size: 10px !important;
    line-height: 5px;
  }
  .xs {
    max-width: 100px;
    visibility: hidden;
  }
  .xs span::after {
    visibility: visible;
    content: "Menu";
  }
}

.responsive {
  max-width: 100%;
  width: 35px;
  /* height: auto; */
}
.ant-popover-inner {
  color: black;
  background-color: lightgray;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: green;
}

.topCard {
  border-radius: 3px;
  -webkit-margin-before: 10px;
          margin-block-start: 10px;
  box-shadow: 1px 1px 0.5px #f0f0f0;
}

.cardTitle {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
}

.indicatorCard {
  border-radius: 3px;
  -webkit-margin-before: 10px;
          margin-block-start: 10px;

  /* box-shadow: 0px 0px 4px 1px rgba(170, 227, 185, 0.41); */
}

.bottomCard {
  background: rgba(255, 244, 128, 0.21);
}

@media all and (max-width: 768px),
  only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (min-device-pixel-ratio: 2) and (max-width: 1024px),
  only screen and (min-resolution: 192dpi) and (max-width: 1024px),
  only screen and (min-resolution: 2dppx) and (max-width: 1024px) {
  .carr {
    top: 50% !important;
  }
}

.card {
    border-radius: 3px;
    box-shadow: 1px 1px 0.5px #f0f0f0;
}

.card-bg {
    border-radius: 3px;
    box-shadow: 1px 1px 0.5px #f0f0f0;
    background: rgba(245, 245, 245, 1);
    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.98) 11%,
        rgba(247, 247, 247, 0.92) 53%,
        rgba(255, 255, 255, 0.89) 66%,
        rgba(255, 255, 255, 0.85) 96%,
        rgba(255, 255, 255, 0.84) 100%
    );
}
@media all and (max-width: 768px),
    only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px),
    only screen and (min-device-pixel-ratio: 2) and (max-width: 1024px),
    only screen and (min-resolution: 192dpi) and (max-width: 1024px),
    only screen and (min-resolution: 2dppx) and (max-width: 1024px) {
    .container {
        max-width: 100%;
        width: auto;
        padding: 16px 16px;
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
    }
    /* .container-date {
    max-width: 100%;
    width: auto;
    white-space: nowrap;
    display: flex;
  } */
}
.Tooltips {
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.p {
    margin-bottom: 0px !important;
}

.GreyTooltip {
    background-color: lightgrey !important;
    color: black !important;
    fill: #fff !important;
    font-size: 12px !important;
}

.Content_Content__1CE8z {
    font-size: 12px !important;
}
.chart-container {
    position: relative;
    margin: auto;
    height: 80vh;
    width: 100%;
}
@media only screen and (orientation: landscape) {
    #turn {
        display: block;
    }
    #container-tom {
        display: none;
    }
}
@media only screen and (orientation: portrait) {
    #turn {
        display: none;
    }
    #container-tom {
        display: block;
    }
}

.steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}

.steps-action {
    margin-top: 24px;
}

.ant-select-combobox .ant-select-arrow {
    display: block;
}

.ant-select-selection__clear {
    opacity: 1;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: left !important;
}

.ant-tabs {
    margin-top: 16px;
}

.ant-table-row:hover {
    cursor: pointer;
}

.ant-table-row:active {
    opacity: 0;
    transition: ease 0.5s;
}

.ant-popover-inner {
    background: #ffffff;
}

.drag-and-drop-wrapper {
    cursor: -webkit-grab;
    cursor: grab;
    min-height: 20px;
    width: 130px;
    height: auto;
    margin-right: 20px;
    padding: 0;
    list-style-type: none;
    overflow: auto;
}

.drag-enter {
    border: 2px dashed gray;
}

.row {
    box-sizing: border-box;
    background: #ccc;
    padding: 5px;
    margin: 0;
    font-size: 14px;
}

.row:hover {
    background: #edf4fa;
    cursor: -webkit-grab;
    cursor: grab;
}

.row:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.drag {
    display: inline-block;
    color: grey;
    padding: 0px 3px;
    cursor: -webkit-grab;
    cursor: grab;
}

.drag:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.arrow_box {
    font-family: "Open Sans", sans-serif;
    position: relative;
    color: #000;
    font-weight: normal;
    font-size: 12px;
    background: #fff;
}

#chart .apexcharts-tooltip {
    color: #fff;
    transform: translateX(10px) translateY(10px);
    overflow: visible !important;
    white-space: normal !important;
}

#chart .apexcharts-tooltip span {
    padding: 2px 10px;
    display: inline-block;
}

#chart .apexcharts-tooltip p {
    font-weight: bold;
    margin: 0px;
    display: inline-block;
}

